import CloudinaryImage from '../common/CloudinaryImage';

import Container from './Container';

export default function OurPartners({ Clients }) {
  return (
    <div
      style={{ backgroundColor: Clients?.bgColor }}
      className="lg:py-[75px] py-[45px] bg-[#F2F5F6]"
    >
      <Container>
        <div className="grid grid-cols-12 gap-6">
          <div className="md:hidden block col-span-12 flex items-center justify-center">
            {Clients?.title && (
              <div className="font-[800] xl:text-[55px] md:text-[32px] text-[26px] leading-tight lg:w-8/12 text-center">
                {Clients?.title}
              </div>
            )}
          </div>
          <div className="lg:col-span-7 col-span-12 grid lg:grid-cols-3 grid-cols-2 gap-5">
            {Clients?.image?.data?.map((img, key) => (
              <div
                key={key}
                className="bg-[#FFFFFF] rounded-[8px] flex items-center justify-center py-6 px-5"
              >
                <CloudinaryImage
                  backendImgUrl={img?.attributes?.url}
                  alt={img?.attributes?.alternativeText}
                  type="icon"
                  className="md:w-8/12 w-6/12"
                />
              </div>
            ))}
          </div>
          <div className="lg:col-span-5 lg:flex hidden col-span-12 flex items-center justify-center">
            {Clients?.title && (
              <div className="font-[800] xl:text-[55px] md:text-[32px] text-[26px] leading-tight xl:mb-[24px] mb-[20px] lg:w-8/12 text-center">
                {Clients?.title}
              </div>
            )}
          </div>
        </div>
      </Container>
    </div>
  );
}
